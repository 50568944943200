import { makeTraceHttpRequestHeaders } from './utils/make-trace-http-request-headers';
var state = {
  context: null
};
export var generateSpanId = function generateSpanId() {
  return Array.from(new Array(16), function () {
    return Math.floor(Math.random() * 16).toString(16);
  }).join('');
};
export var setInteractionActiveTrace = function setInteractionActiveTrace(interactionId, experienceType) {
  setActiveTrace(interactionId.replace(/-/g, ''), generateSpanId(), experienceType);
};
export var setActiveTrace = function setActiveTrace(traceId, spanId, type) {
  state.context = {
    traceId: traceId,
    spanId: spanId,
    type: type
  };
};
export var getActiveTrace = function getActiveTrace() {
  return state.context || undefined;
};
export var clearActiveTrace = function clearActiveTrace() {
  state.context = null;
};
export var getActiveTraceHttpRequestHeaders = function getActiveTraceHttpRequestHeaders(_url) {
  if (state.context === null) {
    return null;
  }
  var _state$context = state.context,
    traceId = _state$context.traceId,
    spanId = _state$context.spanId;
  return makeTraceHttpRequestHeaders(traceId, spanId);
};