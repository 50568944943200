import './polyfills';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';

import ErrorBoundary, { UnexpectedErrorPage } from './components/ErrorBoundary';
import ServerErrorInterceptor from './containers/ServerErrorInterceptor';
import UnSupportedMobileBrowserInterceptor from './containers/UnSupportedMobileBrowserInterceptor';
import {
  captureException,
  initClient as initErrorReportingClient,
} from './utilities/analytics/error-reporting';
import LocaleSwitcher from './components/LocaleSwitcher';
import {
  buildInitialState,
  createAnalyticsClient,
  startupAnalyticEvent,
  pushGlobals,
  initCastle,
} from './utilities/init-app-state';
import App from './containers/App';
import { startBrowserMetrics, BrowserMetricsBoundary } from './browser-metrics';
import AnalyticsClientProvider from './containers/AnalyticsClientProvider';
import { createAppStore } from './stores/redux.store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FeatureFlagProvider, FeatureGateProvider } from './providers';

// Render a spinner to remove the "failed to load scripts" error while loading the app
const container = document.getElementById('root');

const root = createRoot(container!);

root.render(
  <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
    <Spinner size="large" />
  </div>
);

buildInitialState()
  .then(async initialState => {
    const { initialStoreState, cspNonce } = initialState;

    initErrorReportingClient(initialStoreState.microbranding);

    const analyticsClient = createAnalyticsClient(initialState);

    startupAnalyticEvent(analyticsClient);

    startBrowserMetrics(analyticsClient);

    pushGlobals(initialStoreState.appConfig, cspNonce);

    const store = createAppStore(initialStoreState);

    if (initialStoreState.appConfig) {
      initCastle(initialStoreState.appConfig);
    }

    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
        },
      },
    });

    root.render(
      <Provider store={store}>
        <LocaleSwitcher>
          <ErrorBoundary analyticsClient={analyticsClient}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <AnalyticsClientProvider>
                  <FeatureFlagProvider analyticsClient={analyticsClient}>
                    <ServerErrorInterceptor>
                      <UnSupportedMobileBrowserInterceptor>
                        <BrowserMetricsBoundary>
                          <App />
                        </BrowserMetricsBoundary>
                      </UnSupportedMobileBrowserInterceptor>
                    </ServerErrorInterceptor>
                  </FeatureFlagProvider>
                </AnalyticsClientProvider>
              </BrowserRouter>
            </QueryClientProvider>
          </ErrorBoundary>
        </LocaleSwitcher>
      </Provider>
    );
  })
  .catch(error => {
    captureException(error);

    const emptyStore = createAppStore({});
    root.render(
      <Provider store={emptyStore}>
        <LocaleSwitcher>
          <UnexpectedErrorPage />
        </LocaleSwitcher>
      </Provider>
    );
  });
