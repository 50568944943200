import { ThunkMiddleware } from '@reduxjs/toolkit';
import { createSearchParams, NavigateFunction } from 'react-router-dom';
import queryString from 'query-string';

import {
  mfaRecoverError,
  mfaRecoverResponse,
  mfaVerifyError,
} from '../actions/multi-factor-actions';
import { Dispatch, Action, ThunkAction, URLParameters } from '../types';
import { AnalyticsClient } from '../utilities/analytics/analytics-web-client';
import { captureException } from '../utilities/analytics/error-reporting';
import { addFlag } from '../actions/flag-actions';
import messages from '../containers/MultiFactorPage.messages';
import { RootState } from '../stores/redux.store';
import { currentPerimeter, getCurrentPerimeter } from '../utilities/env';

/* *********************
 * id-authentication MFA
 * *********************/
export const idAuthMfaMiddleware: ThunkMiddleware<RootState, Action> = ({
  dispatch,
}) => next => action => {
  switch (action.type) {
    case 'MFA_VERIFICATION_VERIFY':
      next(action);
      // The action that calls fetch (/verify) and redirects
      return dispatch(verifyOtpCodeAction(action));
    case 'MFA_VERIFICATION_RESEND_OTP':
      next(action);
      // The action that calls fetch to resend OTP code
      // Transport (i.e. via SMS or Email) is determined by the mfa token.
      return dispatch(resendOtpAction(action));
    case 'MFA_VERIFICATION_RECOVER':
      next(action);
      // The action that verifies recovery code
      return dispatch(verifyRecoveryCodeAction(action));
    case 'MFA_VERIFICATION_CONFIRMED_RECOVER':
      next(action);
      // The action that calls /mfa/authorize and redirects
      return dispatch(confirmedRecoveryCodeAction(action));
    default:
      next(action);
  }
};

type ResendOtpParams = {
  params: string;
  analyticsClient: AnalyticsClient;
  navigate: NavigateFunction;
};

export const resendOtpAction = ({
  params,
  analyticsClient,
  navigate,
}: ResendOtpParams): ThunkAction => async (dispatch, getState) => {
  try {
    const response = await fetch(`/rest/mfa/resend${params}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getState().csrfToken,
      },
    });

    const queryParams: URLParameters = queryString.parse(params);
    switch (response.status) {
      case 200:
        const { transactionToken } = await response.json();
        navigate(
          {
            search: `?${new URLSearchParams({ transactionToken })}`,
          },
          { replace: true }
        );
        break;
      case 400:
        redirectToLoginErrorOn400IncorrectTokenResponse({
          analyticsClient,
          page: '2svChallengeScreen',
          subjectId: '2svIncorrectToken',
          currentQueryParams: queryParams,
        });
        break;
      case 429:
        analyticsClient.errorShownEvent('2svChallengeScreen', '2svTooManySms');
        handleError(dispatch);
        break;
      default:
        const error = await response.text();
        throw new Error(error || response.statusText);
    }
  } catch (error) {
    analyticsClient.errorShownEvent('2svChallengeScreen', '2svUnknownError');
    handleError(dispatch);
    captureException(error);
  }
};

type MfaVerificationParams = {
  params: string;
  otpCode: string;
  analyticsClient: AnalyticsClient;
};

export const verifyOtpCodeAction = ({
  params,
  otpCode,
  analyticsClient,
}: MfaVerificationParams): ThunkAction => async (dispatch, getState) => {
  try {
    const isCommercial = getCurrentPerimeter() === 'commercial';
    const token = isCommercial
      ? await import('@castleio/castle-js')
          .then(async ({ createRequestToken }) => await createRequestToken())
          .catch(() => '')
      : null;

    const response = await fetch(`/rest/mfa/verify${params}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getState().csrfToken,
      },
      body: JSON.stringify({ otpCode, ...(token && { token }) }),
    });

    switch (response.status) {
      case 200:
        const { transactionToken, redirectUri } = await response.json();
        if (redirectUri) {
          // fast5
          window.location.assign(redirectUri);
        } else {
          const queryParams: URLParameters = queryString.parse(params);
          queryParams.transactionToken = transactionToken;
          const authorizeResponse = await fetch(
            `/rest/mfa/authorize?${queryString.stringify(queryParams)}`
          );

          if (!authorizeResponse.ok) {
            const error = await authorizeResponse.text();
            throw new Error(error || authorizeResponse.statusText);
          }

          const { redirectUri } = await authorizeResponse.json();
          window.location.assign(redirectUri);
        }
        break;
      case 400:
        const queryParams: URLParameters = queryString.parse(params);
        redirectToLoginErrorOn400IncorrectTokenResponse({
          analyticsClient,
          page: '2svChallengeScreen',
          subjectId: '2svIncorrectToken',
          currentQueryParams: queryParams,
        });
        break;
      case 403:
      case 429:
        analyticsClient.errorShownEvent('2svChallengeScreen', '2svIncorrectCode');
        dispatch(mfaVerifyError({ errorCode: 'invalid_otp', message: 'invalid otp code' }));
        break;
      default:
        const error = await response.text();
        throw new Error(error || response.statusText);
    }
  } catch (error) {
    analyticsClient.errorShownEvent('2svChallengeScreen', '2svUnknownError');
    handleError(dispatch);
    captureException(error);
  }
};

type MfaRecoveryCodeVerificationParams = {
  params: string;
  recoveryCode: string;
  analyticsClient: AnalyticsClient;
  navigate: NavigateFunction;
};

export const verifyRecoveryCodeAction = ({
  params,
  recoveryCode,
  analyticsClient,
  navigate,
}: MfaRecoveryCodeVerificationParams): ThunkAction => async (dispatch, getState) => {
  try {
    const response = await fetch(`/rest/mfa/verify/recoverycode${params}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getState().csrfToken,
      },
      body: JSON.stringify({ recoveryCode }),
    });

    switch (response.status) {
      case 200:
        const { recoveryCode, transactionToken } = await response.json();
        const newParams = {
          continue: queryString.parse(params).continue,
          transactionToken: transactionToken.toString(),
        };
        navigate(
          {
            search: `?${createSearchParams(newParams)}`,
          },
          { replace: true }
        );
        dispatch(mfaRecoverResponse({ recoveryCode }));
        break;
      case 400:
        const queryParams: URLParameters = queryString.parse(params);
        redirectToLoginErrorOn400IncorrectTokenResponse({
          analyticsClient,
          page: '2svRecoveryKeyScreen',
          subjectId: '2svIncorrectToken',
          currentQueryParams: queryParams,
        });
        break;
      case 403:
      case 429:
        analyticsClient.errorShownEvent('2svRecoveryKeyScreen', '2svIncorrectCode');
        dispatch(
          mfaRecoverError({
            errorCode: 'invalid_recovery_code',
            message: 'invalid recovery code',
          })
        );
        break;
      default:
        const error = await response.text();
        throw new Error(
          `Unexpected response from POST /rest/mfa/verify/recoverycode: ${
            error || response.statusText
          }`
        );
    }
  } catch (error) {
    analyticsClient.errorShownEvent('2svRecoveryKeyScreen', '2svUnknownError');
    handleError(dispatch);
    captureException(error);
  }
};

type ConfirmedRecoveryCodeParams = {
  params: string;
  analyticsClient: AnalyticsClient;
};

export const confirmedRecoveryCodeAction = ({
  params,
  analyticsClient,
}: ConfirmedRecoveryCodeParams): ThunkAction => async (dispatch, getState) => {
  try {
    const response = await fetch(`/rest/mfa/authorize${params}`);

    switch (response.status) {
      case 200:
        const { redirectUri } = await response.json();
        window.location.assign(redirectUri);
        break;
      case 400:
      case 403:
        const queryParams: URLParameters = queryString.parse(params);
        redirectToLoginErrorOn400IncorrectTokenResponse({
          analyticsClient,
          page: '2svSavedNewRecoveryKey',
          subjectId: '2svIncorrectToken',
          currentQueryParams: queryParams,
        });
        break;
      default:
        const error = await response.text();
        throw new Error(
          `Unexpected response from GET /rest/mfa/authorize: ${error || response.statusText}`
        );
    }
  } catch (error) {
    analyticsClient.errorShownEvent('2svSavedNewRecoveryKey', '2svUnknownError');
    handleError(dispatch);
    captureException(error);
  }
};

function handleError(dispatch: Dispatch) {
  return dispatch(addFlag('error', messages.mfVerifyErrorTitle, messages.mfVerifyErrorDesc));
}

function redirectToLoginErrorOn400IncorrectTokenResponse({
  analyticsClient,
  page,
  subjectId,
  currentQueryParams,
}: {
  analyticsClient: AnalyticsClient;
  page: string;
  subjectId: string;
  currentQueryParams: URLParameters;
}) {
  analyticsClient.errorShownEvent(page, subjectId);
  delete currentQueryParams.transactionToken;
  delete currentQueryParams.errorCode;
  const currentQueryString = queryString.stringify(currentQueryParams);
  const extraParamsString = currentQueryString === '' ? '' : `&${currentQueryString}`;

  window.location.assign(
    `/login?prompt=true&errorCode=login.form.token.expired${extraParamsString}`
  );
}
