import { bind } from 'bind-event-listener';
import { getViewportHeight, getViewportWidth } from './getViewport';
export var AbortEvent = {
  wheel: 'wheel',
  keydown: 'keydown',
  resize: 'resize'
};
export var attachAbortListeners = function attachAbortListeners(window, initialViewPort, callback) {
  var unbindWheel = bind(window, {
    type: AbortEvent.wheel,
    listener: function listener(e) {
      callback(AbortEvent.wheel, e.timeStamp);
    },
    options: {
      passive: true,
      once: true
    }
  });
  var unbindKeydown = bind(window, {
    type: AbortEvent.keydown,
    listener: function listener(e) {
      callback(AbortEvent.keydown, e.timeStamp);
    },
    options: {
      once: true
    }
  });
  var unbindResize = bind(window, {
    type: AbortEvent.resize,
    listener: function listener(e) {
      if (getViewportWidth() !== initialViewPort.w || getViewportHeight() !== initialViewPort.h) {
        callback(AbortEvent.resize, e.timeStamp);
        unbindResize();
      }
    }
  });
  return [unbindWheel, unbindKeydown, unbindResize];
};