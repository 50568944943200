import { envType, perimeterType } from './analyticsWebTypes';
const PROD_HOST = 'as.atlassian.com/api/v1';
const STARGATE_PROD_HOST = 'api-private.atlassian.com/gasv3/api/v1';
const STAGING_HOST = 'as.staging.atl-paas.net/api/v1';
const STARGATE_STAGING_HOST = 'api-private.stg.atlassian.com/gasv3/api/v1';
const FEDRAMP_STAGING_HOST = 'as.staging.atl-paas-us-gov-mod.net/api/v1';
const FEDRAMP_PROD_HOST = 'as.atlassian-us-gov-mod.com/api/v1';
const STARGATE_FEDRAMP_STAGING_HOST = 'api.stg.atlassian-us-gov-mod.com/gasv3/api/v1';
const STARGATE_FEDRAMP_PROD_HOST = 'api.atlassian-us-gov-mod.com/gasv3/api/v1';
const STARGATE_PROXY_PATH = '/gateway/api/gasv3/api/v1';
const handleFedRampStagingDirect = () => {
  return FEDRAMP_STAGING_HOST;
};
const handleFedRampStagingStargate = () => {
  return STARGATE_FEDRAMP_STAGING_HOST;
};
const handleFedRampModerate = ({
  useStargate,
  env,
  useLegacyUrl,
  envOverride
}) => {
  if (env === envType.LOCAL || env === envType.DEV) {
    throw Error('No LOCAL or DEV environments for FedRAMP.');
  }
  if (env === envType.PROD && envOverride) {
    throw Error('Cannot override to FedRAMP Sandbox for Prod.');
  }
  if (!useStargate) {
    return env === envType.PROD ? FEDRAMP_PROD_HOST : handleFedRampStagingDirect();
  }
  if (useLegacyUrl) {
    return env === envType.PROD ? STARGATE_FEDRAMP_PROD_HOST : handleFedRampStagingStargate();
  }

  /**
   * Stargate proxy path now needs to be the default to work around browser restrictions with cookies.
   * For more details, see:
   *  - https://experimentation-platform.atlassian.net/browse/MHD-326, and
   *  - https://hello.atlassian.net/wiki/spaces/I/pages/1217206437/api-private+is+broken+is+your+product+impacted
   */
  return window.location.host + STARGATE_PROXY_PATH;
};
const handleCommercial = ({
  useStargate,
  env,
  useLegacyUrl
}) => {
  if (!useStargate) {
    return env === envType.PROD ? PROD_HOST : STAGING_HOST;
  }
  if (useLegacyUrl) {
    return env === envType.PROD ? STARGATE_PROD_HOST : STARGATE_STAGING_HOST;
  }

  /**
   * Stargate proxy path now needs to be the default to work around browser restrictions with cookies.
   * For more details, see:
   *  - https://experimentation-platform.atlassian.net/browse/MHD-326, and
   *  - https://hello.atlassian.net/wiki/spaces/I/pages/1217206437/api-private+is+broken+is+your+product+impacted
   */
  return window.location.host + STARGATE_PROXY_PATH;
};
export function selectHost({
  useStargate,
  env,
  useLegacyUrl,
  perimeter,
  envOverride
}) {
  if (perimeter === perimeterType.FEDRAMP_MODERATE) {
    return handleFedRampModerate({
      useStargate,
      env,
      useLegacyUrl,
      envOverride
    });
  }
  return handleCommercial({
    useStargate,
    env,
    useLegacyUrl
  });
}