import FeatureFlagWebClient, {
  ClientOptions,
  EnvironmentType,
  FeatureFlagUser,
  Identifiers,
  PerimeterType,
} from '@atlassiansox/feature-flag-web-client';
import Cookie from 'js-cookie';
import { AnalyticsClientImpl } from '../analytics/analytics-web-client';
import { currentEnvType, currentPerimeter, EnvType, Perimeter } from '../env';

const FFS_ID_KEY = 'atlassian.account.ffs.id';

export function getFfsId(): string | undefined {
  const ffsId = Cookie.get(FFS_ID_KEY);
  return ffsId && ffsId.replace(/(^")|("$)/g, '');
}

const FF_ENV_MAP: Record<EnvType, EnvironmentType> = {
  unknown: EnvironmentType.LOCAL,
  dev: EnvironmentType.DEV,
  staging: EnvironmentType.STAGING,
  prod: EnvironmentType.PROD,
} as const;

// Corresponding Launch Darkly Instance: https://app.launchdarkly.com/identity/production/features
// API keys are found at https://developer.atlassian.com/platform/frontend-feature-flags/resources/api-keys/
const LD_API_KEYS: Record<Perimeter, Record<EnvironmentType, string>> = {
  commercial: {
    [EnvironmentType.LOCAL]: 'e91005d2-b812-461e-b15d-d564d1006cf2',
    [EnvironmentType.DEV]: 'e91005d2-b812-461e-b15d-d564d1006cf2',
    [EnvironmentType.STAGING]: 'c1b4f462-6a1b-4805-9b2a-3ebd77b6d0ac',
    [EnvironmentType.PROD]: '41ebe9bd-4502-4a8d-b018-9de8cd0aabc7',
  },
  'fedramp-moderate': {
    [EnvironmentType.LOCAL]: '7c485929-ab26-4fa3-bdf9-5d4e0aea9496', // Using fedm-staging
    [EnvironmentType.DEV]: '7c485929-ab26-4fa3-bdf9-5d4e0aea9496', // Using fedm-staging
    [EnvironmentType.STAGING]: '7c485929-ab26-4fa3-bdf9-5d4e0aea9496',
    [EnvironmentType.PROD]: '977f05ed-a0cf-46db-8e04-996d257f9fab',
  },
} as const;

let ffClient: FeatureFlagWebClient;

export const LD_ENV = FF_ENV_MAP[currentEnvType];
export const LD_API_KEY = LD_API_KEYS[currentPerimeter][LD_ENV];

export const initializeFeatureFlagClient = (analyticsClient: AnalyticsClientImpl) => {
  if (!ffClient) {
    const options: ClientOptions = {
      productKey: 'identity',
      environment: LD_ENV,
      ...(currentPerimeter === 'fedramp-moderate' && {
        // perimeter should be undefined for commercial env
        perimeter: PerimeterType.FEDRAMP_MODERATE,
      }),
    };

    const user: FeatureFlagUser = {
      isAnonymous: true,
    };

    const ffsId = getFfsId();

    if (ffsId) {
      user.identifier = {
        type: Identifiers.FF_CLIENT_ANONYMOUS_ID,
        value: ffsId,
      };
    }

    ffClient = new FeatureFlagWebClient(LD_API_KEY, analyticsClient.client, user, options);
  }

  return ffClient;
};

const defaultValues = {
  'identity.aid_signup.apple.auth.enabled': true,
  'identity.aid_signup.microsoft.auth.enabled': true,
  'identity.aid-frontend.apple.login.outage.advisory.enabled': false,
  'identity.aid-frontend.google.login.outage.advisory.enabled': false,
  'identity.aid-frontend.google.one.tap.enabled': false,
  'identity.aid-frontend.microsoft.login.outage.advisory.enabled': false,
  'identity.aid-frontend.slack.login.outage.advisory.enabled': false,
  'identity.id-authentication.sms-enrollment-new-captcha.enabled': false,
  'identity.sign-in-service.check-username.recaptcha.enabled': false,
  'identity.sign-in-service.recaptcha.combo.behavior.enabled': false,
  'identity.sign-in-with-slack.enabled': false,
  'identity.aid-frontend.advanced-cobranding.enabled': false,
} as const;

export type FeatureFlagId = keyof typeof defaultValues;

export type FeatureFlagOverrides = Partial<Record<FeatureFlagId, boolean>>;

export const isBooleanFeatureFlagSet = (
  flagKey: FeatureFlagId,
  shouldSendExposureEvent = true
): boolean => {
  /**
   * window.featureFlagOverrides are used by Storybook and for quicker local-dev loop
   * as it skips @atlassiansox/feature-flag-web-client and LD client's caching.
   */
  const overide = window.featureFlagOverrides?.[flagKey];

  if (typeof overide === 'boolean') {
    return overide;
  }

  const defaultValue = defaultValues[flagKey];

  if (!ffClient) {
    return defaultValue;
  }

  return ffClient.getFlagValue(flagKey, defaultValue, {
    shouldSendExposureEvent,
  });
};
