"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INIT_TIMEOUT_DEFAULT_MS = void 0;
var DEFAULT_FEATURE_GATE_API = 'https://featuregates.org/v1/';
var DEFAULT_EVENT_LOGGING_API = 'https://events.statsigapi.net/v1/';
exports.INIT_TIMEOUT_DEFAULT_MS = 3000;
var StatsigSDKOptions = /** @class */ (function () {
    function StatsigSDKOptions(options) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        if (options == null) {
            options = {};
        }
        var api = (_a = options.api) !== null && _a !== void 0 ? _a : DEFAULT_FEATURE_GATE_API;
        this.api = api.endsWith('/') ? api : api + '/';
        this.disableCurrentPageLogging = (_b = options.disableCurrentPageLogging) !== null && _b !== void 0 ? _b : false;
        this.environment = (_c = options.environment) !== null && _c !== void 0 ? _c : null;
        this.loggingIntervalMillis = this.normalizeNumberInput(options.loggingIntervalMillis, {
            default: 10000,
            min: 1000,
            max: 60000,
        });
        this.loggingBufferMaxSize = this.normalizeNumberInput(options.loggingBufferMaxSize, {
            default: 100,
            min: 2,
            max: 500,
        });
        this.disableNetworkKeepalive = (_d = options.disableNetworkKeepalive) !== null && _d !== void 0 ? _d : false;
        this.overrideStableID = (_e = options.overrideStableID) !== null && _e !== void 0 ? _e : null;
        this.localMode = (_f = options.localMode) !== null && _f !== void 0 ? _f : false;
        this.initTimeoutMs =
            options.initTimeoutMs && options.initTimeoutMs >= 0
                ? options.initTimeoutMs
                : exports.INIT_TIMEOUT_DEFAULT_MS;
        this.disableErrorLogging = (_g = options.disableErrorLogging) !== null && _g !== void 0 ? _g : false;
        this.disableAutoMetricsLogging = (_h = options.disableAutoMetricsLogging) !== null && _h !== void 0 ? _h : false;
        this.initializeValues = (_j = options.initializeValues) !== null && _j !== void 0 ? _j : null;
        var eventLoggingApi = (_l = (_k = options.eventLoggingApi) !== null && _k !== void 0 ? _k : options.api) !== null && _l !== void 0 ? _l : DEFAULT_EVENT_LOGGING_API;
        this.eventLoggingApi = eventLoggingApi.endsWith('/')
            ? eventLoggingApi
            : eventLoggingApi + '/';
        var eventLoggingApiForRetries = (_m = options.eventLoggingApiForRetries) !== null && _m !== void 0 ? _m : eventLoggingApi;
        this.eventLoggingApiForRetries = eventLoggingApiForRetries.endsWith('/')
            ? eventLoggingApiForRetries
            : eventLoggingApiForRetries + '/';
        this.disableLocalStorage = (_o = options.disableLocalStorage) !== null && _o !== void 0 ? _o : false;
        this.ignoreWindowUndefined = (_p = options === null || options === void 0 ? void 0 : options.ignoreWindowUndefined) !== null && _p !== void 0 ? _p : false;
        this.updateUserCompletionCallback =
            (_q = options === null || options === void 0 ? void 0 : options.updateUserCompletionCallback) !== null && _q !== void 0 ? _q : null;
        this.disableAllLogging = (_r = options === null || options === void 0 ? void 0 : options.disableAllLogging) !== null && _r !== void 0 ? _r : false;
    }
    StatsigSDKOptions.prototype.normalizeNumberInput = function (input, bounds) {
        if (input == null) {
            return bounds.default;
        }
        return Math.max(Math.min(input, bounds.max), bounds.min);
    };
    return StatsigSDKOptions;
}());
exports.default = StatsigSDKOptions;
