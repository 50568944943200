import '@atlaskit/css-reset';
import { setGlobalTheme } from '@atlaskit/tokens';
import React, { Suspense, useLayoutEffect } from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ScreenSizeListener from '../components/ScreenSizeListener';
import applicationDestinationSelector from '../selectors/application-destination';
import { useAppSelector } from '../stores/redux.store';
import { CobrandingProvider, getCurrentCobranding } from '../utilities/cobranding/cobranding';
import { LoginHintType, useLoginHint } from '../utilities/hooks/login-hint';
import messages from './App.messages';
import FlagContainer from './FlagContainer';
import {
  AuthCallbackErrorPage,
  CancelAccountDeletionPage,
  ChangePasswordPage,
  ChooseMethodPage,
  ConfigureAppPage,
  ConfigurePhonePage,
  ContinueAsPage,
  CreateAccountConfirmationPage,
  DeletionRequestConfirmationPage,
  EmailConfirmationPage,
  ExpiredPasswordPage,
  InactiveAccountPage,
  LoginConsentNoticePage,
  LoginPage,
  LogoutPage,
  MfaAppsPage,
  MfaEnrollmentPage,
  MfaPromotePage,
  MigrateAccountConfirmationPage,
  MultiFactorPage,
  PendingDeletionPage,
  RecoveryPage,
  RegisterSecurityKeyPage,
  RemoveAccountPage,
  ResetPasswordPage,
  SaveRecoveryKeyPage,
  SecurityExpiredLinkPage,
  SecuritySuccessPage,
  SelectAccountPage,
  SignupPage,
  SocialLoginRecoveryPage,
  StepUpSsoPage,
  StepUpStartPage,
  UnauthorizedPage,
  VerifyEmailOtpPage,
  VerifyOrReverifyEmailErrorPage,
  VerifyOrReverifyEmailSentPage,
  WelcomePage,
  WelcomeSentPage,
} from './LazyPages';
import { getCurrentPerimeter } from '../utilities/env';
import MobileAppMetaTags from '../utilities/MobileAppMetaTags';

const App = () => {
  useLayoutEffect(() => {
    void setGlobalTheme({ colorMode: 'light' });
  }, []);

  const { search } = useLocation();
  const { formatMessage } = useIntl();
  const loginHint = useLoginHint();
  const defaultTitle = formatMessage(messages.headTitle);

  const contextPath = useAppSelector(state => state.appConfig.contextPath);
  const microbranding = useAppSelector(state => state.microbranding);
  const cobranding = useAppSelector(state => state.cobranding);

  const applicationDestination = useAppSelector(state =>
    applicationDestinationSelector(state, window.location.search)
  );

  return (
    <Suspense fallback={null}>
      <Helmet key="helmet" defaultTitle={defaultTitle} titleTemplate={`%s - ${defaultTitle}`} />
      <MobileAppMetaTags />
      <CobrandingProvider
        value={{
          cobranding: getCurrentCobranding(cobranding),
          isMobileApp: microbranding.isMobileApp,
        }}>
        <ScreenSizeListener key="screenSizeListener">
          <Routes>
            <Route
              path={`${contextPath}/2step/enrollment/1-get-app`}
              element={<ChooseMethodPage />}
            />
            <Route
              path={`${contextPath}/2step/enrollment/2-configure-app`}
              element={<ConfigureAppPage />}
            />
            <Route
              path={`${contextPath}/2step/enrollment/2-configure-phone`}
              element={<ConfigurePhonePage />}
            />
            <Route
              path={`${contextPath}/2step/enrollment/2-register-security-key`}
              element={<RegisterSecurityKeyPage />}
            />
            <Route
              path={`${contextPath}/2step/enrollment/3-connect-phone`}
              element={<ConfigureAppPage isConnectPhone />}
            />
            <Route
              path={`${contextPath}/2step/enrollment/4-save-recovery-key`}
              element={<SaveRecoveryKeyPage />}
            />
            <Route path={`${contextPath}/2step/enrollment`} element={<MfaEnrollmentPage />} />
            <Route path="/2step/recover/expired" element={<MultiFactorPage isExpired />} />
            <Route path="/2step/recover" element={<RecoveryPage />} />
            <Route path={`${contextPath}/2step`} element={<MfaAppsPage />} />
            <Route path="/login/resetpassword" element={<ResetPasswordPage />} />
            <Route path="/login/continue-as" element={<ContinueAsPage />} />
            <Route path="/login/select-account" element={<SelectAccountPage />} />
            <Route path="/login/remove-account" element={<RemoveAccountPage />} />
            <Route path="/login/cancel-account-deletion" element={<CancelAccountDeletionPage />} />
            <Route
              path="/login/deletion-request-confirmation"
              element={<DeletionRequestConfirmationPage />}
            />
            <Route path="/login/resetexpiredpassword" element={<ExpiredPasswordPage />} />
            <Route path="/login/changepassword/*" element={<ChangePasswordPage />} />
            <Route path="/login/inactive" element={<InactiveAccountPage />} />
            <Route path="/login/pending-deletion" element={<PendingDeletionPage />} />
            <Route path="/login/mfa" element={<MultiFactorPage isExpired={false} />} />
            <Route path="/login/consent" element={<LoginConsentNoticePage />} />
            <Route path="/login/social/confirmation" element={<CreateAccountConfirmationPage />} />
            <Route
              path="/login/*"
              element={<Navigate to={{ pathname: '/login', search }} replace />}
            />
            <Route
              path="/login"
              element={
                loginHint && loginHint.type === LoginHintType.NotEmailAddress ? (
                  <UnauthorizedPage
                    applicationDestination={applicationDestination}
                    loginHint={loginHint}
                  />
                ) : (
                  <LoginPage />
                )
              }
            />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/mf" element={<MultiFactorPage isExpired={false} />} />
            <Route
              path="/signup/migrate-confirmation"
              element={<MigrateAccountConfirmationPage />}
            />
            <Route path="/signup/welcome/sent" element={<WelcomeSentPage />} />
            <Route path="/signup/welcome/*" element={<WelcomePage />} />
            <Route path="/signup/verify-email/otp" element={<VerifyEmailOtpPage />} />
            <Route
              path="/signup"
              element={
                getCurrentPerimeter() === 'commercial' ? (
                  <SignupPage />
                ) : (
                  <Navigate to={{ pathname: '/login', search }} replace />
                )
              }
            />
            <Route path="/verify-email/change-email" element={<EmailConfirmationPage />} />
            <Route path="/verify-email/sent" element={<VerifyOrReverifyEmailSentPage />} />
            <Route path="/verify-email/*" element={<VerifyOrReverifyEmailErrorPage />} />
            <Route path="/security/expiredlink" element={<SecurityExpiredLinkPage />} />
            <Route
              path="/security/successfulreset"
              element={<SecuritySuccessPage successType="password-reset" />}
            />
            <Route
              path="/security/successfulverification"
              element={<SecuritySuccessPage successType="verification" />}
            />
            <Route path="/login/security-screen" element={<MfaPromotePage />} />
            <Route path="/social/recovery" element={<SocialLoginRecoveryPage />} />
            <Route path="/error" element={<AuthCallbackErrorPage />} />
            <Route path="/step-up/start" element={<StepUpStartPage />} />
            <Route path="/step-up/sso" element={<StepUpSsoPage />} />
            <Route path="*" element={<Navigate to={{ pathname: '/login', search }} replace />} />
          </Routes>
          <FlagContainer />
        </ScreenSizeListener>
      </CobrandingProvider>
    </Suspense>
  );
};

export default App;
