/*
 * Its confusing that we have two analytic clients, so this comment may help to understand what is going on.
 * This is a new client which we are migrating to. We should have both for a transition period.
 */

import { currentEnv, currentPerimeter, currentEnvType } from '../env';
import { captureException, addBreadcrumb, setLocale } from './error-reporting';

import AnalyticsWebClient, {
  envType,
  originType,
  tenantType,
  userType,
  platformType,
} from '@atlassiansox/analytics-web-client';
import { dangerouslyCreateSafeString } from '@atlassiansox/analytics';
import React, { useCallback, useContext, useEffect } from 'react';
import { State as Microbranding } from '../../reducers/microbranding-reducer';
import { State as Cobranding } from '../../reducers/cobranding-reducer';
import { UserFlow } from '../../types';
import { isMobileOidc } from '../oidc/oidc';
import getOriginTracingPropertiesFromUrl from './tracing';
import { LoginHintType } from '../hooks/login-hint';
import { lookupApplication } from '../applications';
import { getFirstContentfulPaint, getFirstPaint } from '../performance';
import { LoginType } from '../social-login';
import { getPageLoadMetric } from '../../browser-metrics';
import { RecaptchaType } from '../../hooks/useRecaptcha';

const tags = ['identity'];

function getEnv(): envType {
  switch (currentEnv) {
    case 'local':
      return envType.LOCAL;
    case 'dev':
      return envType.DEV;
    case 'stg':
    case 'stg-fedm':
      return envType.STAGING;
    case 'prod':
    case 'prod-fedm':
      return envType.PROD;
    default:
      return envType.DEV;
  }
}

export interface EventAttributes {
  // Used to track slow rollout
  ffsId?: string;
  // These are used for exposure events
  flagKey?: string;
  reason?: string;
  ruleId?: string;
  // Track if page has been opened in a browser popup
  isInPopup?: boolean;
  // These are a long list of other potential attributes, to be documented
  redirectReason?: string;
  firstProductAccessed?: string;
  isMobileApp?: boolean;
  isEnterprise?: boolean;
  successfulAuthentication?: boolean;
  errorCode?: string;
  value?: any;
  referer?: string;
  redirectedFromLogin?: boolean;
  accountCount?: number;
  accountIndex?: number;
  loginHintType?: LoginHintType;
  loginType?: LoginType;
  cobrandingConfluence?: string;
  cobrandingJira?: string;
  hideLogout?: boolean;
  sessionCount?: number;
  sessionIndex?: number;
  origin?: string;
  status?: 'checked' | 'unchecked' | 'not_shown';
  linkExpired?: boolean;
  isMicrosoftButtonEnabled?: boolean;
  isAppleButtonEnabled?: boolean;
  isSlackButtonEnabled?: boolean;
  reasonViewingScreen?: string;
  requestAccessLinkShown?: boolean;
  marketingConsent?: string;
  errorName?: string;
  errorMessage?: string;
  errorComponentStack?: string;
  errorStack?: string;
  isAppleHiddenEmail?: boolean;
  recovKeyOption?: string;
  confirmationCreateAccountNeeded?: boolean;
  confirmationVerifyEmailNeeded?: boolean;
  verificationCodeEntered?: boolean;
  flow?: string;
  application?: string;
  isAuthenticatedUser?: boolean;
  redirectType?: string;
  signupType?: string;
  result?: string;
  source?: string;
  browserWarningMessageShown?: boolean;
  multifactorMethod?: string;
  noAccess?: boolean;
  accounts?: Array<string>;
  eligibility?: Record<any, any>;
  hasError?: boolean;
  autoSubmit?: boolean;
  suggestedAccountShown?: boolean;
  formError?: string;
  isWebAuthnSupported?: boolean;
  errors?: Record<string, boolean>;
  recaptchaType?: RecaptchaType;
}

export type UiEventSubject = 'button' | 'form' | 'input' | 'error' | 'link' | 'checkbox';

export type UiEventAction = 'submitted' | 'clicked' | 'changed' | 'shown';

export interface UiEvent {
  page: string;
  action: UiEventAction;
  subject: UiEventSubject;
  // Unique id of event subject (ex. signupFormSubmitButton)
  subjectId?: string;
  attributes?: EventAttributes;
}

/*
 * Tracking event could be anything (like user created), so all fields are plain strings
 * ex.
 * {
 *   page:      'createAccountPage',
 *   action:    'created',
 *   subject:   'user',
 *   subjectId: '<userId>',
 * }
 */
export interface TrackingEvent {
  page: string;
  action: string;
  subject: string;
  subjectId?: string;
  attributes?: EventAttributes;
}

/*
 * Operational event is similar to tracking event in structure
 * but is for events that are not necessarily triggered by the user
 * ex.
 * {
 *   page:      'createAccountPage',
 *   action:    'created',
 *   subject:   'user',
 *   subjectId: '<userId>',
 * }
 */
export interface OperationalEvent {
  page: string;
  action: string;
  subject: string;
  subjectId?: string;
  attributes?: EventAttributes;
}

export type ApdexType = 'initialLoad' | 'transition';

export interface ApdexEvent {
  task: string;
  taskId?: string;
  type: ApdexType;
  additionalAttributes?: object;
}

export function appendReferer(eventAttributes: EventAttributes): EventAttributes {
  try {
    const referer = new URL(document.referrer);
    referer.search = ''; // lets minimise possibility of tokens in referer by cleaning up query params
    return {
      ...eventAttributes,
      referer: referer.toString(),
    };
  } catch (e) {
    return eventAttributes;
  }
}

export function appendReasonViewingScreen(
  eventAttributes: EventAttributes,
  userFlow?: UserFlow
): EventAttributes {
  if (userFlow) {
    return {
      ...eventAttributes,
      reasonViewingScreen: userFlow,
    };
  }

  return eventAttributes;
}

function determinePlatform(microbranding: Microbranding) {
  if (isMobileOidc(microbranding.oidcContext) || microbranding.isEmbedded) {
    return platformType.MOBILE_WEB;
  }

  return platformType.WEB;
}

let analyticsClient: AnalyticsWebClient | undefined;

type CreateDefaultWebClientOptions = {
  microbranding: Microbranding;
  locality?: string;
  tenantCloudId?: string;
  userId?: string;
  settings?: {
    flushWaitInterval?: number;
  };
};

export function createDefaultWebClient({
  microbranding,
  locality,
  tenantCloudId,
  userId,
}: CreateDefaultWebClientOptions): AnalyticsWebClient {
  const platform = determinePlatform(microbranding);
  const locale = locality || 'en';
  const env = getEnv();

  const apiHost = ['prod', 'staging'].includes(currentEnvType)
    ? window.location.host + '/gateway/api/gasv3/api/v1'
    : undefined;

  if (!analyticsClient) {
    analyticsClient = new AnalyticsWebClient(
      {
        env,
        product: 'identity',
        origin: originType.WEB,
        platform,
        locale,
        perimeter: currentPerimeter,
      },
      {
        apiHost,
      }
    );
  }

  if (userId) {
    analyticsClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, userId);
  }

  if (tenantCloudId) {
    analyticsClient.setTenantInfo(tenantType.CLOUD_ID, tenantCloudId);
  } else {
    analyticsClient.setTenantInfo(tenantType.NONE);
  }

  return analyticsClient;
}

export interface AnalyticsClient {
  formSubmittedEvent(page: string, formId: string, attributes?: EventAttributes): Promise<void>;
  buttonClickedEvent(page: string, buttonId: string, attributes?: EventAttributes): Promise<void>;
  linkClickedEvent(page: string, linkId: string, attributes?: EventAttributes): Promise<void>;
  checkboxChangedEvent(
    page: string,
    checkboxId: string,
    attributes?: EventAttributes
  ): Promise<void>;
  trackingEvent(event: TrackingEvent): void;
  operationalEvent(event: OperationalEvent, callback?: (error?: Error) => void): void;
  pageViewedEvent(page: string, eventAttributes?: EventAttributes): void;
  errorShownEvent(
    page: string,
    subjectId: string,
    eventAttributes?: EventAttributes
  ): Promise<void>;
  uiEvent(event: UiEvent): Promise<void>;
  stopApdexEvent(apdexEvent: ApdexEvent): void;
  stopInitialLoadApdexEvent(task: string): void;
  getInstance(): AnalyticsWebClient;
  setFfsId(ffsId: string): void;
}

type ConstructorArgs = {
  microbranding: Microbranding;
  cobranding: Cobranding;
  locale: string | undefined;
  tenantCloudId: string | undefined;
  userId: string | undefined;
  client?: AnalyticsWebClient;
};

export class AnalyticsClientImpl implements AnalyticsClient {
  client: AnalyticsWebClient;

  private readonly microbranding: Microbranding;
  private readonly cobranding: Cobranding;
  private ffsId: string;

  constructor(args: ConstructorArgs) {
    const {
      microbranding,
      cobranding,
      locale,
      tenantCloudId,
      userId,
      client = createDefaultWebClient({ microbranding, locality: locale, tenantCloudId, userId }),
    } = args;

    try {
      setLocale(locale);
      this.microbranding = microbranding;
      this.cobranding = cobranding;
      this.client = client;
    } catch (error) {
      captureException(error);
    }
  }

  formSubmittedEvent(page: string, formId: string, attributes?: EventAttributes): Promise<void> {
    return this.uiEvent({
      page,
      action: 'submitted',
      subject: 'form',
      subjectId: formId,
      attributes,
    });
  }

  buttonClickedEvent(page: string, buttonId: string, attributes?: EventAttributes): Promise<void> {
    return this.uiEvent({
      page,
      action: 'clicked',
      subject: 'button',
      subjectId: buttonId,
      attributes,
    });
  }

  linkClickedEvent(page: string, linkId: string, attributes?: EventAttributes): Promise<void> {
    return this.uiEvent({
      page,
      action: 'clicked',
      subject: 'link',
      subjectId: linkId,
      attributes,
    });
  }

  checkboxChangedEvent(
    page: string,
    checkboxId: string,
    attributes?: EventAttributes
  ): Promise<void> {
    return this.uiEvent({
      page,
      action: 'changed',
      subject: 'checkbox',
      subjectId: checkboxId,
      attributes,
    });
  }

  trackingEvent(event: TrackingEvent) {
    const attributes = this.enrichAttributes(event.attributes);
    addBreadcrumb({
      category: 'tracking',
      message: `UI ${event.action} tracking event occured at ${event.subject} ${event.subjectId} on page ${event.page}`,
      data: {
        // this has to be a flat structure
        source: event.page,
        actionSubject: event.subject,
        action: event.action,
        actionSubjectId: event.subjectId,
        ...attributes,
      },
    });

    try {
      this.client.sendTrackEvent({
        source: event.page,
        actionSubject: event.subject,
        action: event.action,
        actionSubjectId: event.subjectId,
        attributes,
        tags,
      });
    } catch (error) {
      captureException(error, { event });
    }
  }

  operationalEvent(event: OperationalEvent, callback?: (error?: Error) => void) {
    const attributes = this.enrichAttributes(event.attributes);
    addBreadcrumb({
      category: 'operational',
      message: `${event.action} operational event occured at ${event.subject} ${event.subjectId} on page ${event.page}`,
      data: {
        // this has to be a flat structure
        source: event.page,
        actionSubject: event.subject,
        action: event.action,
        actionSubjectId: event.subjectId,
        ...attributes,
      },
    });

    try {
      this.client.sendOperationalEvent(
        {
          source: event.page,
          actionSubject: event.subject,
          action: event.action,
          actionSubjectId: event.subjectId,
          attributes,
          tags,
        },
        callback
      );
    } catch (error) {
      captureException(error, { event });
      if (callback) {
        callback(error);
      }
    }
  }

  async pageViewedEvent(page: string, eventAttributes?: EventAttributes) {
    const attributes = this.enrichAttributes(eventAttributes);

    addBreadcrumb({
      category: 'pageViewed',
      message: 'Page viewed event of page id ' + page,
      data: {
        source: page,
        ...attributes,
      },
    });

    try {
      const metric = getPageLoadMetric(page);

      if (metric.getData().state !== 'finished') {
        metric.stop();
      }
    } catch (error) {
      captureException(error, { page });
    }

    try {
      await this.client.sendScreenEvent({
        name: page,
        attributes,
      });
    } catch (error) {
      captureException(error, { page });
    }
  }

  errorShownEvent(page: string, subjectId: string, eventAttributes?: EventAttributes) {
    return this.uiEvent({
      page,
      action: 'shown',
      subject: 'error',
      subjectId,
      attributes: eventAttributes,
    });
  }

  uiEvent(event: UiEvent): Promise<void> {
    const attributes = this.enrichAttributes(event.attributes);
    addBreadcrumb({
      category: 'ui',
      message: `UI ${event.action} event occured at ${event.subject} ${event.subjectId} on page ${event.page}`,
      data: {
        // this has to be a flat structure
        source: event.page,
        actionSubject: event.subject,
        action: event.action,
        actionSubjectId: event.subjectId,
        ...attributes,
      },
    });

    return new Promise(resolve => {
      try {
        this.client.sendUIEvent(
          {
            source: event.page,
            actionSubject: event.subject,
            action: event.action,
            actionSubjectId: event.subjectId,
            attributes,
            tags,
          },
          () => resolve()
        );
      } catch (error) {
        captureException(error, { event });
        // error is already captured, no point in putting the burden upon consumers by rejecting since they likely want to proceed
        resolve();
      }
    });
  }

  stopApdexEvent(apdexEvent: ApdexEvent) {
    try {
      this.client.stopApdexEvent(apdexEvent);
    } catch (error) {
      captureException(error, { event: apdexEvent });
    }
  }

  stopInitialLoadApdexEvent(task: string) {
    this.stopApdexEvent({
      task,
      type: 'initialLoad',
      additionalAttributes: {
        properties: {
          firstPaint: getFirstPaint(),
          firstContentfulPaint: getFirstContentfulPaint(),
        },
      },
    });
  }

  getInstance() {
    return this.client;
  }

  setFfsId(ffsId: string) {
    this.ffsId = ffsId;
  }

  private enrichAttributes(eventAttributes?: EventAttributes): EventAttributes {
    const originTracingProperties = getOriginTracingPropertiesFromUrl(window.location.href, {
      mapAttribute: value =>
        typeof value === 'string' ? dangerouslyCreateSafeString(value) : value,
    });
    const applicationData = lookupApplication(this.cobranding);
    const application = applicationData ? applicationData.application : 'unknown';
    const applicationAnalyticAttributes = {
      firstProductAccessed: application,
      experience: applicationData?.experience,
    };

    return {
      ...applicationAnalyticAttributes,
      ...originTracingProperties,
      isMobileApp: this.microbranding.isMobileApp,
      ...eventAttributes,
      ffsId: this.ffsId,
      isInPopup: Boolean(window.opener && window.opener !== window),
    };
  }
}

export const AnalyticsClientContext = React.createContext<AnalyticsClient | null>(null);

export const useAnalyticsClient = () => {
  const context = useContext(AnalyticsClientContext);

  if (!context) {
    throw new Error('useAnalyticsClient used outside of AnalyticsProvider.');
  }

  return context;
};

/** Send the given event when the component is mounted. Will only be sent once, make sure that the event is fully constructed. */
export const useTrackingEvent = (event: TrackingEvent) => {
  const analyticsClient = useAnalyticsClient();

  useEffect(() => {
    analyticsClient.trackingEvent(event);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const usePageViewedEvent = (page: string, eventAttributes?: EventAttributes) => {
  const analyticsClient = useAnalyticsClient();

  useEffect(() => {
    analyticsClient.pageViewedEvent(page, eventAttributes);
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useButtonClickedEvent = (
  page: string,
  buttonId: string,
  attributes?: EventAttributes
) => {
  const analyticsClient = useAnalyticsClient();

  return useCallback(() => {
    analyticsClient.buttonClickedEvent(page, buttonId, attributes);
  }, [analyticsClient, page, buttonId, attributes]);
};

export const useLinkClickedEvent = (page: string, linkId: string, attributes?: EventAttributes) => {
  const analyticsClient = useAnalyticsClient();

  return useCallback(() => {
    analyticsClient.linkClickedEvent(page, linkId, attributes);
  }, [analyticsClient, page, linkId, attributes]);
};

export const useFormSubmittedEvent = (
  page: string,
  formId: string,
  attributes?: EventAttributes
) => {
  const analyticsClient = useAnalyticsClient();

  return useCallback(() => {
    analyticsClient.formSubmittedEvent(page, formId, attributes);
  }, [analyticsClient, page, formId, attributes]);
};

export const useOperationalEvent = (
  operationalEvent: OperationalEvent,
  callback?: (error?: Error) => void
) => {
  const analyticsClient = useAnalyticsClient();

  useEffect(() => {
    analyticsClient.operationalEvent(operationalEvent, callback);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
