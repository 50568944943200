import type { Identifiers } from '@atlaskit/feature-gate-js-client';
import React, { ReactNode } from 'react';
import { useAppSelector } from '../stores/redux.store';
import { getFfsId } from '../utilities/feature-flags';
import { FeatureGatesInit } from './FeatureGatesInit';

type FeatureGateProviderProps = {
  children: ReactNode;
};

export const FeatureGateProvider = ({ children }: FeatureGateProviderProps) => {
  const identifiers = useAppSelector(
    (state): Identifiers => ({
      tenantId: state.context.tenantCloudId,
      atlassianAccountId: state.context.userId,
      /*
       * randomizationId is the closest equivalent to ffsId
       * https://atlassian.slack.com/archives/C04PR2YE4UC/p1727076842335159
       */
      randomizationId: getFfsId(),
    })
  );

  return (
    <FeatureGatesInit identifiers={identifiers} initializingComponent={null}>
      {children}
    </FeatureGatesInit>
  );
};
