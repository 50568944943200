export function getViewportWidth() {
  var document = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.document;
  var documentWidth;
  try {
    documentWidth = document.documentElement.clientWidth || 0;
  } catch (e) {
    documentWidth = 0;
  }
  return Math.max(documentWidth, window.innerWidth || 0);
}
export function getViewportHeight() {
  var document = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.document;
  var documentHeight;
  try {
    documentHeight = document.documentElement.clientHeight || 0;
  } catch (e) {
    documentHeight = 0;
  }
  return Math.max(documentHeight, window.innerHeight || 0);
}